import React, { useContext, createContext, useState } from "react";
import "./App.scss";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
  useOutlet,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Loader from "components/Loader";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Layout from "./components/Layout";
import LoginPage from "./pages/Login";
import DashboardLayout from "./components/DashboardLayout";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { fetchDictionary } from "./actions/global";
import { useStore, useSelector, useDispatch } from "react-redux";

import Button from "./components/Button";
const ModerationPage = React.lazy(() => import("./pages/Moderation"));
const StartPage = React.lazy(() => import("./pages/Start"));
const ChatPage = React.lazy(() => import("./pages/Chat"));
const EditPlace = React.lazy(() => import("./pages/EditPlace"));
const ListForm = React.lazy(() => import("./pages/ListForm"));
const EpisodeForm = React.lazy(() => import("./pages/EpisodeForm"));
const Lists = React.lazy(() => import("./pages/Lists"));
const Communities = React.lazy(() => import("./pages/Communities"));
const EditCommunity = React.lazy(() => import("./pages/EditCommunity"));
const Episodes = React.lazy(() => import("./pages/Episodes"));
const TalkForm = React.lazy(() => import("./pages/TalkForm"));
const Talks = React.lazy(() => import("./pages/Talks"));
const EmailTemplates = React.lazy(() => import("./pages/EmailTemplates"));
const PromptTemplates = React.lazy(() => import("./pages/PromptTemplates"));
const PushTemplates = React.lazy(() => import("./pages/PushTemplates"));
const PushTemplateForm = React.lazy(() => import("./pages/PushTemplateForm"));
const SundaySerendipities = React.lazy(() =>
  import("./pages/SundaySerendipities")
);
const ProfileActions = React.lazy(() => import("./pages/ProfileActions"));
const UserFieldsValidationTool = React.lazy(() =>
  import("./pages/UserFieldsValidationTool")
);
const FindCity = React.lazy(() => import("./pages/FindCity"));
const EmailTemplateForm = React.lazy(() => import("./pages/EmailTemplateForm"));
const PromptTemplateForm = React.lazy(() =>
  import("./pages/PromptTemplateForm")
);

const PlaceForm = React.lazy(() => import("./pages/PlaceForm"));
const TestEmbedding = React.lazy(() => import("./pages/TestEmbedding"));
const MostSimilarUsers = React.lazy(() => import("./pages/MostSimilarUsers"));

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App({ cable }) {
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    dispatch(fetchDictionary());
  }, []);

  const location = useLocation();

  return (
    <AuthProvider>
      {/* <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={"next"}
          timeout={300}
        > */}
      <Routes location={location}>
        <Route element={<Layout />}>
          <Route index element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <DashboardLayout />
              </RequireAuth>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<Loader />}>
                  <StartPage cable={cable} />
                </React.Suspense>
              }
            />
            <Route
              path="chat"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ChatPage cable={cable} />
                </React.Suspense>
              }
            />
            <Route
              path="moderation"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ModerationPage />
                </React.Suspense>
              }
            />
            <Route
              path="communities"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Communities />
                </React.Suspense>
              }
            />
            <Route
              path="communities/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EditCommunity />
                </React.Suspense>
              }
            />
            <Route
              path="lists"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Lists />
                </React.Suspense>
              }
            />
            <Route
              path="lists/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ListForm />
                </React.Suspense>
              }
            />
            <Route
              path="lists/:list_id/places/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PlaceForm />
                </React.Suspense>
              }
            />
            <Route
              path="episodes/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EpisodeForm />
                </React.Suspense>
              }
            />
            <Route
              path="episodes"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Episodes />
                </React.Suspense>
              }
            />
            <Route
              path="talks"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Talks />
                </React.Suspense>
              }
            />
            <Route
              path="talks/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <TalkForm />
                </React.Suspense>
              }
            />
            <Route
              path="sunday_serendipities"
              element={
                <React.Suspense fallback={<Loader />}>
                  <SundaySerendipities />
                </React.Suspense>
              }
            />
            <Route
              path="profile_actions"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ProfileActions />
                </React.Suspense>
              }
            />
            <Route
              path="fields_validation_tool"
              element={
                <React.Suspense fallback={<Loader />}>
                  <UserFieldsValidationTool />
                </React.Suspense>
              }
            />
            <Route
              path="find_city"
              element={
                <React.Suspense fallback={<Loader />}>
                  <FindCity />
                </React.Suspense>
              }
            />
            <Route
              path="test_embedding"
              element={
                <React.Suspense fallback={<Loader />}>
                  <TestEmbedding />
                </React.Suspense>
              }
            />
            <Route
              path="email_templates"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EmailTemplates />
                </React.Suspense>
              }
            />
            <Route
              path="prompt_templates"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PromptTemplates />
                </React.Suspense>
              }
            />
            <Route
              path="prompt_templates/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PromptTemplateForm />
                </React.Suspense>
              }
            />
            <Route
              path="email_templates/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EmailTemplateForm />
                </React.Suspense>
              }
            />
            <Route
              path="push_templates"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PushTemplates />
                </React.Suspense>
              }
            />
            <Route
              path="push_templates/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PushTemplateForm />
                </React.Suspense>
              }
            />
            <Route
              path="most_similar_users"
              element={
                <React.Suspense fallback={<Loader />}>
                  <MostSimilarUsers />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
      {/* </CSSTransition>
      </TransitionGroup> */}
    </AuthProvider>
  );
}

export default App;
